/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Losing your hearing is a big deal, however old you are or whatever your line of work. But despite this, a surprisingly high percentage of Americans who experience hearing loss fail to seek assistance. Until, that is, their hearing becomes so bad that seeing a specialist is unavoidable. But at that point, they may have missed out on assistance which could have slowed the advance of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This exists everywhere in the USA, and Illinois is no exception. Whether you’re trying to hear the music at a High School auditorium in Peoria, or you’re struggling to follow a presentation in Downtown Chicago, hearing matters on a day to day basis. From straining to pick up the sound of animals when hunting in the Shawnee Forest or listening out for the announcements at O’Hare, so much of life depends on the ability to hear clearly. So, take action and seek the right solutions as soon as you start to detect any signs. When you do, hear.com is here to help."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "noise-sources-in-illinois",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noise-sources-in-illinois",
    "aria-label": "noise sources in illinois permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noise sources in Illinois"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You may not know it, but the State of Illinois has a proud tradition of looking after those who are hard of hearing. After all, it was Prairie State native Abraham Lincoln who signed off on the creation of Gallaudet University – the first institution for deaf, dumb and blind people. But even in today’s Illinois, millions of people are at risk of losing their hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some of the most at risk are subject to constant high noise levels in their place of work. For instance, there are over 500 large factories in the state, and almost all of them contain noisy, hazardous machinery. And the farmers of the Prairie State are also at risk, thanks to harvesting equipment and vehicles, while the same applies to haulage professionals and construction workers too. In a busy, prosperous state, hearing loss is a major issue, and it can seriously impact upon your working life, so it makes sense to get things sorted out as soon as possible. But work isn’t the only way people can be exposed to noise in Illinois. Sports crowds can generate huge amounts of noise (well above the 80db associated with hearing loss risk), so regular attendees of Soldier Field or the United Center could well be damaging their hearing by watching their heroes over a period of years."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Driving is a constant source of noise (as are traffic sounds from the state’s numerous highways), while Illinois natives love to hunt and shoot as well. Studies have found that regular hunters have a much higher hearing loss risk, with the risk increasing by 7 percent for every 5 years of hunting. And in a leisure activity where keen hearing makes a real difference, that can be a major problem."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-numbers-behind-illinois-hearing-loss-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-numbers-behind-illinois-hearing-loss-problems",
    "aria-label": "the numbers behind illinois hearing loss problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The numbers behind Illinois' hearing loss problems"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many ways your ears can be exposed to excessive noise in Illinois, but it might help to run through a few statistics to make the picture a little clearer. All across America, around 15% of adults over the age of 18 report some hearing problems."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "25% of those aged from 65-75 report experiencing “disabling hearing loss,” which rises to 50% after the age of 75. If you plug those figures into Illinois, about 2 million residents suffer some degree of hearing loss, including around 800,000 seniors."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Only around a third of sufferers who could benefit from hearing aids actually do so, which means that hundreds of thousands of people in the state are accepting gradual or severe hearing loss without seeking assistance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we want to change that, which is why we offer a statewide service to connect people living with hearing loss with the latest hearing technology."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-hearcom-has-to-offer-illinois-natives",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-hearcom-has-to-offer-illinois-natives",
    "aria-label": "what hearcom has to offer illinois natives permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What hear.com has to offer Illinois natives"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people who suffer from hearing loss come to a sharp realization that they have a problem. After years of noticing slight differences, suddenly their conversations become difficult, or the music seems more distant. If that sounds like you, there’s no need to panic."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com has a network of Partner Providers across Ohio and can put you in touch with the expertise required to resolve your hearing issues. And we also offer the most advanced hearing aids from global market leaders, allowing Ohioans to enjoy the highest possible quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-us-to-connect-with-a-provider-in-illinois",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-us-to-connect-with-a-provider-in-illinois",
    "aria-label": "contact us to connect with a provider in illinois permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact us to connect with a Provider in Illinois"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are experiencing hearing loss and haven’t sought out assistance, now is a great time to do so. Just fill out our online request form for a risk-free trial. After that, we’ll arrange a meeting with one of our Partner Providers, where they can help you fit a hearing aid that meets all your needs. You shouldn’t need to travel far, so why not get in touch? Improving your hearing is just a few clicks away, thanks to hear.com."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
